import Head from 'next/head'
import Image from 'next/image'
import React from 'react';
import moment from 'moment';
import { Dna } from 'react-loader-spinner';
import GamesTable from '../components/GamesTable';
import {b64toBlob} from '../lib/b64toblob';
// @ts-ignore
import { toast } from 'react-toastify';
// import { CsvToHtmlTable } from 'react-csv-to-table';
import dynamic from 'next/dynamic'
import firebase from '../lib/firebase'
import getNextDayOfWeek from '../lib/getNextDayOfWeek'
import GenerateICS from '../components/GenerateICS';
import { faro } from '@grafana/faro-web-sdk';

//@ts-ignore
const CsvToHtmlTable = dynamic(() => import('react-csv-to-table').then((mod) => mod.CsvToHtmlTable), {
  ssr: false
})


const config = {
  game_status_mappings: {
    "pending": {
      "text": "Pending",
      "color": "black"
    },
    "postponed": {
      "text": "Postponed",
      "color": "black",
      "strike": true
    },
    "washout": {
      "text": "WO",
      "color": "black"
    },
    "abandoned": {
      "text": "AB",
      "color": "black"
    },
    "complete": {
      "text": "Finished"
    }
  },
  committee_field_setup_color: "green",
  committee_setup_legend_title: "Ground Comittee field setup",
  team_field_setup_color: "red",
  team_setup_legend_title: "Assigned team on field setup"
}


const LoaderSpinnerOverlay = (props: any) => {
  // @ts-ignore
  let {showLoader, textToDisplay} = props
  return showLoader ? (
    <div style={{
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: '1000',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    }}>
      <Dna
        visible={showLoader}
        height="80"
        width="80"
        ariaLabel="dna-loading"
        wrapperStyle={{}}
        wrapperClass="dna-wrapper"
      />
      {textToDisplay ? <p className='text-3xl text-white'>{textToDisplay}</p> : null}
    </div>
  ) : null

}

export default function Home(props: any) {

  const {remoteConfig} = props;

  //@ts-ignore
  // window.committeeColour = remoteConfig.getValue('committee_field_setup_color')

  const [apiToken, setApiToken] = React.useState('');
  const [assocSlug, setAssocSlug] = React.useState('nsfa');
  const [assocData, setAssocData] = React.useState({});
  const [selectedGroundName, setSelectedGroundName] = React.useState('');
  const [selectedGroundId, setSelectedGroundId] = React.useState('Select a ground...');
  const [selectedClubName, setSelectedClubName] = React.useState('');
  const [selectedClubId, setSelectedClubId] = React.useState('Select a ground...');
  const [dataToRender, setDataToRender] = React.useState([]);
  const [datesToFetch, setDatesToFetch] = React.useState({startDate: moment(getNextDayOfWeek(new Date(), 2)).format('YYYY-MM-DD'), endDate: moment(getNextDayOfWeek(new Date(), 2)).add(1, 'days').format('YYYY-MM-DD')});
  const [currentStep, setCurrentStep] = React.useState('start');
  const [showSpinnerOverlay, setShowSpinnerOverlay] = React.useState(false);
  const [uiToRender, setUiToRender] = React.useState([]);
  const [renderedPdf, setRenderedPdf] = React.useState(false)
  const [renderedPdfData, setRenderedPdfData] = React.useState('')
  const [renderedPdfTitle, setRenderedPdfTitle] = React.useState('')
  const [gamesToSend, setGamesToSend] = React.useState({});
  const [loaderTextToDisplay, setLoaderTextToDisplay] = React.useState('');
  const [majestriClub, setMajestriClub] = React.useState('');
  const [majestriClubName, setMajestriClubName] = React.useState('');
  const [seasonId, setSeasonId] = React.useState('');
  const [majestriFile, setMajestriFile] = React.useState('');
  const [dateTimeGenerated, setDateTimeGenerated] = React.useState(`${moment().format('DD/MM/YYYY')} at ${moment().format('h:mm a')}`);
  const [leaguesData, setLeaguesData] = React.useState([]);
  const [clubsData, setClubsData] = React.useState([]);

  

  const [tableShowGroundName, setTableShowGroundName] = React.useState(false);

  const fetchGamesButtonRef = React.useRef(null);

  React.useEffect(() => {
    if (window.location.pathname !== '/') {
      window.location.replace('/');
    }
  }, [])

  React.useEffect(() => {
    if (document.getElementById('fetchGamesForSelectedGround')) {
      // Ensure end date is not before start date
      if (datesToFetch.startDate > datesToFetch.endDate) {
        toast.error('Start date cannot be after end date');
        //@ts-ignore
        fetchGamesButtonRef.current.disabled = true;
        // Add text-gray-400 class to button
        //@ts-ignore
        fetchGamesButtonRef.current.classList.add('text-gray-400');
      } else {
        //@ts-ignore
        fetchGamesButtonRef.current.disabled = false;
        // Remove text-gray-400 class from button
        //@ts-ignore
        fetchGamesButtonRef.current.classList.remove('text-gray-400');
      }
    }
  }, [datesToFetch])

  const fetchAssociationData = async () => {
    setShowSpinnerOverlay(true);
    setLoaderTextToDisplay('Fetching association data');
    
    let associationData;
    
    await fetch('/api/getAssocData', {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        // @ts-ignore
        assocSlug: assocSlug,
        seasonId
      })
    }).then(async (res) => {
      let response = await res.json();
      console.log(response);

      if (response.status === 'failed') {
        switch (response.error) {
          case 'Tenant association not found':
            toast.error('Association not found');
            break;
          
          default:
            toast.error('Something went wrong');
            break;
        }
        setShowSpinnerOverlay(false);
        return;

      } else {
        response.assocData.grounds = response.groundsData
        response.assocData.clubs = response.clubsData.filter
        response.assocData.clubNames = response.clubsData.map((club: any) => club.attributes.name)
        setAssocData(response.assocData);
        // NOTE: Static Season ID for Winter 2024
        // @ts-ignore
        setSeasonId(process.env.NEXT_PUBLIC_SEASON_ID);
        setMajestriClub(response.clubsData.filter((club: any) => club.attributes.name === 'Hornsby Heights FC')[0]?.id)
        setMajestriClubName('Hornsby Heights FC')
        //@ts-ignore
        setSelectedGroundId(response.groundsData.filter((ground: any) => ground.name === 'Montview Oval')[0]?.hash_id) 
        setSelectedGroundName(response.groundsData.filter((ground: any) => ground.name === 'Montview Oval')[0]?.name);
        setSelectedClubId(response.clubsData.filter((club: any) => club.attributes.name === 'Hornsby Heights FC')[0]?.id) 
        setSelectedClubName('Hornsby Heights FC');
        setClubsData(response.clubsData);
        setCurrentStep('selectGround')
        setShowSpinnerOverlay(false)
      }

    })
  }
  React.useEffect(() => {
    if (!window.location.href.includes('?noskip')) {
      fetchAssociationData();
      getLeagues();
    }
  }, [])

  const getLeagues = async () => {
    setShowSpinnerOverlay(true);
    setLoaderTextToDisplay('Fetching leagues');
    await fetch('/api/getLeagues', {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        // @ts-ignore
        assocSlug: assocSlug
      })
    }).then(async (res) => {
      let response = await res.json();
      console.log(response);
      setShowSpinnerOverlay(false);
      setLeaguesData(response.leaguesData);
    })
  }
  
  const getGamesForGround = async () => {
    faro.api.pushEvent('getGamesForGround', {
      assocSlug: assocSlug,
      groundId: selectedGroundId,
      groundName: selectedGroundName,
      startDate: datesToFetch.startDate,
      endDate: datesToFetch.endDate,
    }); 
    setShowSpinnerOverlay(true);
    setLoaderTextToDisplay(`Fetching games for ${selectedGroundName}`);
    await fetch('/api/getGroundData', {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        // @ts-ignore
        assocId: assocData.id,
        // @ts-ignore
        apiToken: apiToken,
        // @ts-ignore
        startDate: moment(datesToFetch.startDate).utc().toISOString(),
        // @ts-ignore
        endDate: moment(datesToFetch.endDate).utc().toISOString(),
        seasonId,
        groundId: selectedGroundId,
        showGround: false
      })
    }).then(async (res) => {
      let response = await res.json()
      console.log(response);
      setTableShowGroundName(false);
      setDataToRender(response.groundGames);
      setShowSpinnerOverlay(false)
      setCurrentStep('showGames');
      console.log(response.gamesByDate)
      //@ts-ignore
      window.gamesByDate = response.gamesByDate
      processGames(response.gamesByDate, false);
      // setGamesByDate(response.gamesByDate)
      setDateTimeGenerated(`${moment().format('DD/MM/YYYY')} at ${moment().format('h:mm a')}`)
    })
  };

  function containsAny(str: any, substrings: any) {
    for (let i = 0; i < substrings.length; i++) {
      if (str?.includes(substrings[i])) {
        return substrings[i];
      }
    }
    return false;
  }

  const getGamesForClub = async () => {
    faro.api.pushEvent('getGamesForClub', {
      assocSlug: assocSlug,
      clubId: selectedClubId,
      clubName: selectedClubName,
      startDate: datesToFetch.startDate,
      endDate: datesToFetch.endDate,
    });
    setShowSpinnerOverlay(true);
    setLoaderTextToDisplay(`Fetching games for ${selectedClubName}`);
    await fetch('/api/getClubData', {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        // @ts-ignore
        apiToken: apiToken,
        // @ts-ignore
        startDate: datesToFetch.startDate,
        // @ts-ignore
        endDate: datesToFetch.endDate,
        seasonId,
        clubId: selectedClubId,
        showGround: true,
        // @ts-ignore
        assocId: assocData.id
      })
    }).then(async (res) => {
      setTableShowGroundName(true);
      let response = await res.json()
      console.log(response);
      setDataToRender(response.clubGames);
      setShowSpinnerOverlay(false)
      setCurrentStep('showGames');
      console.log(response.gamesByDate)
      //@ts-ignore
      window.gamesByDate = response.gamesByDate
      processGames(response.gamesByDate, true);
      // setGamesByDate(response.gamesByDate)
      setDateTimeGenerated(`${moment().format('DD/MM/YYYY')} at ${moment().format('h:mm a')}`)
    })
  }

  const processGames = (gamesByDate: any, showGroundName: boolean) => {
    let uiToRender: any = [];
    let index = 0;
    let gameDate = '';
    let gamesToSend: any = [];

    Object.values(gamesByDate).forEach((date: any, currIndex) => {
      let fieldNames: Array<String> = [];
      let highlightGame = false;
      let markCommittee = false;
      console.log(index, currIndex)
      if (currIndex !== index) {
        uiToRender.push(
          <React.Fragment key={currIndex}>
            <hr style={{visibility: 'hidden', height: '20px'}}></hr>
            <hr style={{visibility: 'hidden', height: '20px'}}></hr>
            <hr style={{visibility: 'hidden', height: '20px'}}></hr>
            <hr style={{visibility: 'hidden', height: '20px'}}></hr>
            <hr style={{visibility: 'hidden', height: '20px'}}></hr>
            <hr style={{visibility: 'hidden', height: '20px'}}></hr>
            <hr style={{visibility: 'hidden', height: '20px'}}></hr>
            {showGroundName && <hr style={{visibility: 'hidden', height: '20px'}}></hr>}
            <hr />
            <hr />
            <hr />
            <hr />
            <hr />
            <hr />
            <hr />
            {showGroundName && <hr />}
          </React.Fragment>
        )
        gamesToSend.push({
          kickOffTime: '',
          league: '',
          homeTeam: '',
          awayTeam: '',
          field: '',
          ground: '',
          date: ''
        })
        // gamesToSend.push({
        //   kickOffTime: '',
        //   league: '',
        //   homeTeam: '',
        //   awayTeam: '',
        //   field: '',
        //   date: ''
        // })

        fieldNames = [];
        highlightGame = false;
        markCommittee = false;
      }
      date.sort(function (a: any, b: any) {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      }).forEach((game: any, currGameIndex: any) => {
        if (game.home_team_name?.includes('Turramurra \\ Kissing Point')) {
          // @ts-ignore
          game.home_club_name = 'Turramurra / Kissing Point'
          game.home_team_name = 'Turramurra / Kissing Point'
        } else {
          // @ts-ignore
          game.home_club_name = containsAny(game.home_team_name, assocData.clubNames)
        }
        if (game.away_team_name?.includes('Turramurra \\ Kissing Point')) {
          // @ts-ignore
          game.away_club_name = 'Turramurra / Kissing Point'
          game.away_team_name = 'Turramurra / Kissing Point'
        } else {
          
          // @ts-ignore
          game.away_club_name = containsAny(game.away_team_name, assocData.clubNames)
        }
        game.base_field_name = game.field_name

        console.log(gameDate, moment(game.date).format('DD/MM'))
        if (moment(game.date).format('DD/MM') !== gameDate && currGameIndex !== 0) {
          uiToRender.push(
            <React.Fragment key={currIndex}>
              <hr style={{visibility: 'hidden', height: '20px'}}></hr>
              <hr style={{visibility: 'hidden', height: '20px'}}></hr>
              <hr style={{visibility: 'hidden', height: '20px'}}></hr>
              <hr style={{visibility: 'hidden', height: '20px'}}></hr>
              <hr style={{visibility: 'hidden', height: '20px'}}></hr>
              <hr style={{visibility: 'hidden', height: '20px'}}></hr>
              <hr style={{visibility: 'hidden', height: '20px'}}></hr>
              {showGroundName && <hr style={{visibility: 'hidden', height: '20px'}}></hr>}
              <hr />
              <hr />
              <hr />
              <hr />
              <hr />
              <hr />
              <hr />
              {showGroundName && <hr />}
            </React.Fragment>
          )
          gamesToSend.push({
            kickOffTime: '',
            league: '',
            homeTeam: '',
            awayTeam: '',
            field: '',
            ground: '',
            date: ''
          })
          // gamesToSend.push({
          //   kickOffTime: '',
          //   league: '',
          //   homeTeam: '',
          //   awayTeam: '',
          //   field: '',
          //   date: ''
          // })
          fieldNames = [];
          highlightGame = false;
          markCommittee = false;
        }
        if (game.base_field_name && !fieldNames.includes(game.base_field_name)) {
          fieldNames.push(game.base_field_name);
          // Check if club neither club is home club for that ground
          highlightGame = true;
          if (game.home_club_name !== 'Hornsby Heights FC' && game.away_club_name !== 'Hornsby Heights FC') {
            markCommittee = true;  
          }
        }

        if (game.home_team_name === null || game.away_team_name === null) {
          game.isBye = true;
          game.status = 'BYE';
          game.field = 'BYE';
          game.home_team_name === null ? 'BYE' : game.home_team_name
          game.away_team_name === null ? 'BYE' : game.away_team_name
          game.ground_name = 'BYE'
        } else {
          game.isBye = false;
        }

        uiToRender.push(
          <React.Fragment key={currIndex}>
            {/*  */}
            {/* @ts-ignore */}
              <p style={{textDecoration: `${config.game_status_mappings[game.status]?.strike ? 'line-through' : game.isBye ? 'line-through' : ''}`,}}>{moment(game.date).format('DD/MM')}</p>
            {/* @ts-ignore */}
              <p style={{textDecoration: `${config.game_status_mappings[game.status]?.strike ? 'line-through' : game.isBye ? 'line-through' : ''}`,}}>{moment(game.date).format('ddd, h:mm a')}</p>
            {/* @ts-ignore */}
              <p style={{color: config.game_status_mappings[game.status]?.color}}>{config.game_status_mappings[game.status]?.text}</p>
            {/* @ts-ignore */}
              <p style={{textDecoration: `${config.game_status_mappings[game.status]?.strike ? 'line-through' : game.isBye ? 'line-through' : ''}`,color: `${highlightGame ? markCommittee ? config.committee_field_setup_color : config.team_field_setup_color : ''}`}}>{game.field_name}</p>
              {/* <p className={`${highlightGame ? markCommittee ? 'text-green-500' : 'text-red-500' : ''}`}>{game.attributes.base_field_name}</p> */}
              {/* @ts-ignore */}
              <p style={{textDecoration: `${config.game_status_mappings[game.status]?.strike ? 'line-through' : game.isBye ? 'line-through' : ''}`,}}>{`${game.league_name}`.replace('Division', 'Div.').replace('Under ', 'U')}</p>
              {/* @ts-ignore */}
              <p style={{textDecoration: `${config.game_status_mappings[game.status]?.strike ? 'line-through' : game.isBye ? 'line-through' : ''}`,}}>{game.home_club_name} {game.home_team_name ? `${game.home_team_name}` : ''}</p>
              {/* @ts-ignore */}
              <p style={{textDecoration: `${config.game_status_mappings[game.status]?.strike ? 'line-through' : game.isBye ? 'line-through' : ''}`,}}>{game.away_club_name} {game.away_team_name ? ` ${game.away_team_name}` : ''}</p>
              {/* @ts-ignore */}
              {showGroundName && <p style={{textDecoration: `${config.game_status_mappings[game.status]?.strike ? 'line-through' : game.isBye ? 'line-through' : ''}`,}}>{game.ground_name}</p>}
              {/* @ts-ignore */}
              <hr />
              <hr />
              <hr />
              <hr />
              <hr />
              <hr />
              {showGroundName && <hr />}
              <hr />
            </React.Fragment>
        );
        gamesToSend.push({
          kickOffTime: moment(game.date).format('ddd, h:mm a'),
          league: `${game.league_name}`.replace('Division', 'Div.').replace('Under ', 'U'),
          homeTeam: `${game.home_club_name} ${game.home_team_group ? `- ${game.home_team_group}` : ''}`,
          awayTeam: `${game.away_club_name}  ${game.away_team_group ? `- ${game.away_team_group}` : ''}`,
          field: game.base_field_name,
          ground: game.ground_name,
          date: moment(game.date).format('DD/MM'),
          highlightGame: `${highlightGame ? markCommittee ? config.committee_field_setup_color : config.team_field_setup_color : ''}`,
          matchStatus: {
            // @ts-ignore
            text: config.game_status_mappings[game.status]?.text,
            // @ts-ignore
            color: config.game_status_mappings[game.status]?.color,
            // @ts-ignore
            strike: config.game_status_mappings[game.status]?.strike ? 'line-through' : '',
          },
        })
        highlightGame = false;
        markCommittee = false;
        gameDate = moment(game.date).format('DD/MM')
        console.log({fieldNames})
      })
      index = currIndex
      fieldNames = [];
      highlightGame = false;
      markCommittee = false;
    })
    setTableShowGroundName(showGroundName);
    setUiToRender(uiToRender);
    setGamesToSend(gamesToSend);

  }

  const downloadPdf = () => {
  
    const contentType = 'application/pdf';
    // const b64Data = 'iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==';
    const b64Data = renderedPdfData;
    
    const blob = b64toBlob(b64Data, contentType);
    const blobUrl = URL.createObjectURL(blob);
    
    const img = document.createElement('a');
    img.href = blobUrl;
    // img.target = "_blank";
    img.innerHTML = 'hi';
    img.style.visibility = "hidden"
    document.body.appendChild(img);  
    img.click();
  }

  const downloadCSV = (csv: string) => {
  
    const blob = new Blob([csv], {type: 'text/csv'});
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    let dateToday = moment().format('DD-MM-YYYY');
    link.setAttribute('download', `${majestriClubName}'s Majestri Fixtures Import - ${dateToday}.csv`);
    document.body.appendChild(link);
    link.click();
  }

  const requestPDF = async () => {
    faro.api.pushEvent('requestPDF', {
      assocSlug: assocSlug,
      groundId: selectedGroundId,
      groundName: selectedGroundName,
      clubId: selectedClubId,
      clubName: selectedClubName,
      startDate: datesToFetch.startDate,
      endDate: datesToFetch.endDate,
    });
    setShowSpinnerOverlay(true);
    setLoaderTextToDisplay(`Generating PDF for ${tableShowGroundName ? selectedClubName : selectedGroundName}`)
    await fetch('https://lapi-fixturelist.nuvu.au/api/generatePdf', {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        gamesToSend: gamesToSend,
        dateToDisplay: `${moment(datesToFetch.startDate).format('Do MMM')} - ${moment(datesToFetch.endDate).format('Do MMM')}`,
        groundName: selectedGroundName,
        clubName: selectedClubName,
        showGround: tableShowGroundName,
        dateTimeGenerated: dateTimeGenerated,
        legendConfig: {
          committeeFieldSetupColor: config.committee_field_setup_color,
          teamFieldSetupColor: config.team_field_setup_color,
          committeeSetupLegendTitle: config.committee_setup_legend_title,
          teamSetupLegendTitle: config.team_setup_legend_title,
        },
        clubId: selectedClubId
      })
    }).then(async (res) => {
      let response = await res.json()
      console.log(response);
      if (response.status === 'failed') {
        console.log('failed')
        setShowSpinnerOverlay(false)
        toast.error('Failed to generate PDF, please try again')
      } else if (response.status === 'success') {
        setRenderedPdf(true)
        setRenderedPdfData(`${response.renderedPdf}`)
        setRenderedPdfTitle(response.pdfName);
        setShowSpinnerOverlay(false);
      }
    })
  
  }
  const requestMajestri = async () => {
    faro.api.pushEvent('requestMajestri', {
      assocSlug: assocSlug,
      groundId: selectedGroundId,
      groundName: selectedGroundName,
      clubId: selectedClubId,
      clubName: selectedClubName,
      startDate: datesToFetch.startDate,
      endDate: datesToFetch.endDate,
    });
    setShowSpinnerOverlay(true);
    setLoaderTextToDisplay(`Generating Majestri Import for ${majestriClubName}`)
    await fetch('/api/generateMajestri', {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        apiToken: apiToken,
        // @ts-ignore
        assocId: assocData.id,
        // // @ts-ignore
        // startDate: datesToFetch.startDate,
        // // @ts-ignore
        // endDate: datesToFetch.endDate,
        seasonId,
        clubId: majestriClub,
        clubName: majestriClubName
      })
    }).then(async (res) => {
      let response = await res.json()
      console.log(response);
      if (response.status === 'failed') {
        console.log('failed')
        setShowSpinnerOverlay(false)
        toast.error('Failed to generate Majestri list, please try again')
      } else if (response.status === 'success') {
        console.log(response.majestriFile)
        setShowSpinnerOverlay(false);
        // setMajestriFile(response.majestriCSV.replaceAll('"', ''))
        downloadCSV(response.majestriCSV.replaceAll('"', ''))
        if (document.getElementById('majestriPreviewName')) {
          // @ts-ignore
          document.getElementById('majestriPreviewName').innerHTML = `Preview of Majestri Fixtures for ${majestriClubName}`
        }
      }
    })
  }

  return (
    <>
      <Head>
        <title>FixtureList</title>
      </Head>
      <main  style={{maxWidth: '100vw'}}>
    <div className='text-lg mt-10 text-center'>
        <button style={{position: 'absolute', top: '10px', left: '10px'}} className='bg-slate-200 px-2 rounded-md' onClick={() => firebase.auth().signOut().then(() => window.location.reload())}>Sign Out</button>
        <LoaderSpinnerOverlay showLoader={showSpinnerOverlay} textToDisplay={loaderTextToDisplay} />
          {currentStep === 'start' ? (<div>
            <label className='mr-3'>Association:</label>
            <input className='bg-slate-200 rounded-md' id='assocSlug' placeholder='nsfa' value={assocSlug} onChange={(e) => {setAssocSlug(e.target.value)}}></input>
            <button className='bg-slate-200 rounded-md px-2 text-lg ml-5' onClick={fetchAssociationData}>Submit</button>
          </div>) : null}
          {/* <br /> */}
          {/* <br /> */}
          {/* <br /> */}
          
          {currentStep === 'selectGround' ? (<div>
            <button style={{position: 'absolute', top: '10px', right: '10px'}} className='bg-slate-200 px-2 rounded-md' onClick={() => setCurrentStep('generateMajestri')}>Generate Majestri Import</button>
            <button style={{position: 'absolute', top: '10px', right: '235px'}} className='bg-slate-200 px-2 rounded-md' onClick={() => setCurrentStep('generateICS')}>Create Calendar Link</button>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} className="text-lg">
              <div>
                <p>Pick a start date:</p>
                <input className='bg-slate-200 rounded-md' type='date' id='startDate' title='Start Date' value={datesToFetch.startDate} onChange={(e) => setDatesToFetch({...datesToFetch, startDate: e.target.value})}></input>
                <p>Pick a end date:</p>
                <input className='bg-slate-200 rounded-md' type='date' id='endDate' title='End Date' value={datesToFetch.endDate} onChange={(e) => setDatesToFetch({...datesToFetch, endDate: e.target.value})}></input>
              </div>
              <hr style={{visibility: 'hidden', height: '15px'}} />
              <p>Select a ground:</p>
              <select className='rounded-md px-2' id='selectGround'  title='Select a ground' value={selectedGroundId} onChange={(e) => {setSelectedGroundId(e.target.value); setSelectedGroundName(e.target.selectedOptions[0].innerText)}}>
                <option disabled>Select a ground...</option>
                {/* @ts-ignore */}
                {Object.values(assocData).length > 0 ? assocData.grounds.map((ground, index) => (
                  <option value={ground.hash_id} key={index}>{ground.name}</option>
                  )): null}
              </select>
              <hr style={{visibility: 'hidden', height: '15px'}} />
              <button id='fetchGamesForSelectedGround' disabled={false} ref={fetchGamesButtonRef} onClick={getGamesForGround} className='bg-slate-200 rounded-md px-2 text-lg'>Fetch Games for {selectedGroundName}</button>
              <hr style={{visibility: 'hidden', height: '15px'}} />
              <strong>OR</strong>
              <hr style={{visibility: 'hidden', height: '15px'}} />
              <p>Select a club:</p>
              <select className='rounded-md px-2' id='selectClub'  title='Select a club' value={selectedClubId} onChange={(e) => {setSelectedClubId(e.target.value); setSelectedClubName(e.target.selectedOptions[0].innerText)}}>
                <option disabled>Select a league...</option>
                {/* @ts-ignore */}
                {clubsData.length > 0 ? clubsData.map((club: any, index) => (
                  <option value={club.id} key={index}>{club.attributes.name}</option>
                  )): null}
              </select>

            </div>
            <br />
            <button id='fetchGamesForSelectedClub' disabled={false} ref={fetchGamesButtonRef} onClick={getGamesForClub} className='bg-slate-200 rounded-md px-2 text-lg'>Fetch Games for {selectedClubName}</button>
          </div>): null}
          <br />
          <br />
          {currentStep === 'showGames' ? (
            <div>
              <button style={{position: 'absolute', top: '10px', left: '110px'}} className='bg-slate-200 px-2 rounded-md' onClick={() => {setCurrentStep('selectGround'); setUiToRender([]); setDataToRender([]); setRenderedPdf(false); setRenderedPdfData(''); setRenderedPdfTitle('')}}>Back</button>
              <p>{`${tableShowGroundName ? `Games for ${selectedClubName}` : `Games at ${selectedGroundName}`}`} between {moment(datesToFetch.startDate).format('dddd, MMMM Do YYYY')} and {moment(datesToFetch.endDate).format('dddd, MMMM Do YYYY')}</p> 
              {renderedPdf === true ? (
                <div>
                    <p>Your PDF is ready to open or download.</p>
                    <br />
                    <button className='bg-slate-200 rounded-md px-2 text-lg' onClick={downloadPdf}>Open PDF</button>
                    <br />
                    <a className='bg-slate-200 rounded-md px-2 text-lg' href={`data:application/pdf;base64,${renderedPdfData}`} download={renderedPdfTitle}>Download PDF</a>
                    {/* <p style={{color: 'white', fontSize: '17px'}}>{renderedPdfData}</p> */}
                  </div>
                  ) : null}
              <div>
                {/* @ts-ignore */}
                { dataToRender.length === 0 ? (
                  <div>
                    <p>Unfortunately, there are no games scheduled at this ground on these dates</p>
                  </div>
                ) : (
                  <div className='items-center'>
                    <button style={{position: 'absolute', top: '10px', right: '10px'}} className='bg-slate-200 px-2 rounded-md' onClick={() => requestPDF()}>Generate PDF</button>
                    <p className="">Data correct as of {dateTimeGenerated}</p>
                    <p className="text-gray-500">Note: Games with missing fields are potentially byes, check Dribl if unsure.</p>
                    <div className='flex flex-col items-center'>
                      <div className='flex flex-row'>
                        <p>Legend: </p>
                        <hr style={{width: '5px', visibility: 'hidden'}} />
                        <div className="row">
                          <div className='box' style={{backgroundColor: config.team_field_setup_color}}></div>
                          <span style={{color: config.team_field_setup_color}}>= {config.team_setup_legend_title}</span>
                        </div>
                        <hr style={{width: '5px', visibility: 'hidden'}} />
                        <div className="row">
                          <div className='box' style={{backgroundColor: config.committee_field_setup_color}}></div>
                          <span style={{color: config.committee_field_setup_color}}>= {config.committee_setup_legend_title}</span>
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col items-center'>                      
                      {/* <GamesTable gamesDataIn={groundGamesData} fieldName={selectedGroundName} /> */}
                      <div className={`${tableShowGroundName ? 'matchesGridPlusGroundName' : 'matchesGrid'}`}>
                        {/* <!-- Table Headers --> */}
                        <p className="text-lg font-mono underline">Date</p>
                        <p className="text-lg font-mono underline">Kick Off Time</p>
                        <p className="text-lg font-mono underline">Status</p>
                        <p className="text-lg font-mono underline">Field</p>
                        <p className="text-lg font-mono underline">League</p>
                        <p className="text-lg font-mono underline">Home Team</p>
                        <p className="text-lg font-mono underline">Away Team</p>
                        {tableShowGroundName && <p className="text-lg font-mono underline">Ground</p>}
                        <hr/>
                        <hr/>
                        <hr/>
                        <hr/>
                        <hr/>
                        <hr/>
                        <hr/>
                        {tableShowGroundName && <hr/>}
                        {uiToRender}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ): null}
          {currentStep === 'generateMajestri' ? (
            <div>
              <button style={{position: 'absolute', top: '10px', left: '110px'}} className='bg-slate-200 px-2 rounded-md' onClick={() => setCurrentStep('selectGround')}>Back</button>
              <p>Select a club to generate an import file for:</p>
              <select className='rounded-md px-2' id='selectClubMajestri' title='Select a Club' value={majestriClub} onChange={(e) => {setMajestriClub(e.target.value); setMajestriClubName(e.target.selectedOptions[0].innerText)}}>
                <option selected disabled>Select a Club...</option>
                {/* @ts-ignore */}
                {clubsData.length > 0 ? clubsData.map((club, index) => (
                  // @ts-ignore
                  <option value={club.id} key={index}>{club.attributes.name}</option>
                )): null}
              </select>
              <br />
              <br />
              <button onClick={requestMajestri} className='bg-slate-200 rounded-md px-2 text-lg' style={{marginBottom: '20px'}}>Generate Majestri File for {majestriClubName}</button>
              {majestriFile ? <p id='majestriPreviewName'>Preview of Majestri Fixtures for</p> : null}
              {typeof window !== 'undefined' ? <CsvToHtmlTable
              // @ts-ignore
                data={majestriFile}
                csvDelimiter=","
                tableClassName="table table-striped table-hover"
              />: null}
            </div>
          ) : null}
          {currentStep === 'generateICS' && <GenerateICS currentStep={currentStep} setCurrentStep={setCurrentStep} assocData={assocData} leaguesData={leaguesData} />}
        </div>
        <div>
          <p>©️ Conor Collins {moment().format('YYYY')}</p>
        </div>
      </main>
    </>
  )
}
